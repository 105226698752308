import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Yougov from './pages/Yougov';
import Intranet from './pages/4017f444-9fde-408e-b702-c09231189b43';

function App() {
  return (
    <>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="yougov" element={<Yougov/>}></Route>
        <Route path="4017f444-9fde-408e-b702-c09231189b43" element={<Intranet/>}></Route>
        
      </Routes>
      
    </BrowserRouter>
    </>
  );
}

export default App;
