import UploadExcel from "../component/UploadExcel";

function Intranet() {
    return (
        <>
            <UploadExcel/>
        </>
    )
}

export default Intranet;
