import React, { useState } from 'react';
import axios from 'axios';

function  UploadExcel() {
    const [uploadFile, setUploadFile] = useState(null);
    const [download, setDownload] = useState(null);
    const [progress,setProgress] = useState({started:false, pc:0});
    const [msg, setMsg] = useState(null);
    const [fileName, setFileName] = useState('Choose or Drop File');


    const handleFileChange = (event) => {
        setUploadFile(event.target.files[0]);
        const fileValue = event.target.value;
        setFileName(fileValue.split("\\").pop())
    };

    const handleUpload = () => {
        if (!uploadFile) {
            alert('Please select a file');
            return;
        }

        const formData = new FormData();
        formData.append('file', uploadFile);

        setMsg('Uploading and Processing...')
        setProgress(prevState => {
            return {...prevState, started: true}
        })

        axios.post('https://api.yourtma.com/api/passionapi/upload', formData, {
                onUploadProgress: (progressEvent)=>{setProgress((prevState) => {
                return {...prevState, pc:progressEvent.progress*100}
            })
        },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log(response.data);
            // Handle response from backend
            setMsg('Upload Successful')
            const { filename } = response.data;
            const downloadLink = `https://api.yourtma.com/api/passionapi/passionfiles/${filename}`;
            setDownload(downloadLink);
        }).catch(error => {
            setMsg(error.response.statusText);
            console.error('Error uploading file:', error);
        });
    };
    // const fake_path=document.getElementById('FileUpload1');
    
    return (
        <>
        
        <main className="container">
            
            
            <div className="uploadWrap" >
                <div className='heading-wrap'>
                    <h1>Upload passion index CSV file</h1>
                </div>
                <div className='fileLabel'>
                    {!progress.started && <div className='fileLabel'>
                        <input id="uploadExcel" type="file" onChange={handleFileChange}  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        <label htmlFor="uploadExcel" className="icon"></label>
                        <p>{fileName}</p>
                        
                    </div>}
                    {/* progress bar start from here */}
                    {progress.started && <progress max="100" value={progress.pc}></progress>}
                    {msg && <span className="msg">{msg}</span>}
                </div>
                {/* <div className=''> */}
                    {!download && !progress.started && <div className='txt-center button-wrap'><button className="button" onClick={handleUpload}>Submit</button></div>}
                    {download && (
                        <div className='txt-center button-wrap'>
                            <a className='downloadLink' href={download}>Download File</a>
                        </div>
                    )}
                {/* </div> */}
            </div>
            
            
        </main>
        </>
    );
}

export default UploadExcel;
