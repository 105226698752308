import Footer from "../component/Footer"
import Header from "../component/Header"
import UploadExcel from "../component/UploadExcel"

function Yougov() {
    return (
        <>
            <Header/>
            <UploadExcel/>
            <Footer/>
        </>
    )
}

export default Yougov
